<template>
  <div class="find-password">
    <header-t />
    <nav2 />
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>重设密码</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="cont">
      <el-card shadow="nerve" class="card">
        <div class="title">重设密码</div>
        <el-form
          ref="passwordForm"
          :model="passwordForm"
          :rules="passwordRules"
          class="form_width"
          label-width="180px"
          size="small"
        >
          <el-form-item prop="phone" label="手机号码：">
            <el-input
              ref="phone"
              v-model="passwordForm.phone"
              clearable
              placeholder="请输入手机号码"
              name="phone"
              class="phone"
              tabindex="1"
              autocomplete="on"
              @change="changePhone"
            />
          </el-form-item>
          <el-form-item prop="captcha" label="短信验证码：">
            <div class="item-wrap">
              <el-input
                ref="captcha"
                v-model="passwordForm.captcha"
                clearable
                placeholder="输入手机验证码"
                name="captcha"
                tabindex="2"
                type="text"
                class="captcha"
              />
              <el-button
                v-bind:disabled="dis"
                type="primary"
                @click="handlegetBtn"
              >
                <span v-if="show">发送验证码</span>
                <span v-else>重新发送({{ count }}s)</span>
              </el-button>
            </div>
          </el-form-item>
          <!-- @keyup.enter.native="handleSubmit" -->
          <el-form-item prop="password" label="登录密码：">
            <el-input
              :key="passwordType"
              ref="password"
              v-model="passwordForm.password"
              clearable
              :type="passwordType"
              placeholder="请输入登录密码"
              name="password"
              class="password"
              tabindex="3"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
            />
          </el-form-item>
          <el-form-item prop="img_captcha" label="图形验证码：">
            <div class="item-wrap">
              <el-input
                ref="img_captcha"
                v-model="passwordForm.img_captcha"
                clearable
                placeholder="请输入验证码"
                maxlength="4"
                name="img_captcha"
                class="captcha"
                tabindex="3"
              />
              <img
                class="captchaImage"
                :src="captchaImage"
                @click="captcheClick"
              />
              <!-- <el-button v-bind:disabled="dis" class="sendCode" type="primary" @click="handlegetBtn">
                <span v-if="show">发送验证码</span>
                <span v-else>重新发送({{ count }}s)</span>
              </el-button> -->
            </div>
          </el-form-item>
          <div class="btn-wrap">
            <el-button
              :loading="loading"
              type="primary"
              class="submit-btn"
              @click.native.prevent="handleSubmit"
              >密码重置</el-button
            >
          </div>
        </el-form>
      </el-card>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderT from '@/components/HeaderT'
import Nav2 from '@/components/Nav2'
import Footer from '@/components/Footer'
import { isPhone } from '@/utils/validate.js'
import { gen, check } from '@/api/common'
import { sendSMS } from '@/api/login'
import { passwordReset } from '@/api/user'
export default {
  name: 'FindPassword',
  components: {
    HeaderT,
    Nav2,
    Footer
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机号不能为空！'))
      } else if (isPhone(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      // 验证长度
      if (value.length < 6 || value.length > 20) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位之间！'));
        return;
      }

      // 验证是否包含字母
      if (!/[a-zA-Z]/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位之间！'));
        return;
      }

      // 验证是否包含数字
      if (!/\d/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位之间！'));
        return;
      }

      // 验证是否包含特殊字符
      // 这里使用的正则表达式匹配常见的特殊字符，您可以根据需要进行调整
      if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位之间！'));
        return;
      }

      // 所有条件都满足
      callback();
    }
    const validateCaptcha = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码！'))
      } else {
        callback()
      }
    }
    const validateImgCaptcha = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码！'))
      } else {
        callback()
      }
    }
    return {
      loading: false, // 按钮loading
      passwordType: 'password', // 密码框类型
      dis: true,
      show: true,
      isGrey: false, //按钮样式
      timer: null, //计时器倒计时
      count: '',
      captchaToken: '', // 验证码token
      captchaImage: '', // base64 图片
      passwordForm: {},
      // 表单检验规则
      passwordRules: {
        phone: [{ required: true, trigger: 'blur', validator: validatePhone }],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ],
        captcha: [
          { required: true, trigger: 'blur', validator: validateCaptcha }
        ],
        img_captcha: [
          { required: true, trigger: 'blur', validator: validateImgCaptcha }
        ]
      }
    }
  },
  mounted() {
    this.getImageCode()
  },
  methods: {
    //生成图形验证码
    getImageCode() {
      const obj = {
        codeType: '1'
      }
      gen(obj).then((res) => {
        console.log(res)
        if (res.status === 200) {
          this.captchaToken = res.data.data.taskId
          this.captchaImage = `data:image/png;base64,${res.data.data.bgPic}`
        }
        // console.log(this.captchaToken);
        // console.log(this.captchaImage);
      })
    },
    //图片验证码校验
    getCheck() {
      if (this.passwordForm.img_captcha) {
        const obj = {
          taskId: this.captchaToken,
          code: this.passwordForm.img_captcha //验证码类型 1图片验证码 2 滑动验证码（暂未实现）
        }
        return new Promise((resolve, reject) => {
          check(obj).then((res) => {
            console.log(res)
            if (res.status === 200) {
              if (res.data.code === 0) {
                resolve()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message,
                  onClose: () => {
                    this.passwordForm.img_captcha = '' //  清空
                  }
                })
                // this.getImageCode() // 更新验证码
                reject()
                throw new Error(res.data.message)
              }
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
              reject()
              throw new Error(res.data.message)
            }
          })
        })
      } else {
        this.$message({
          type: 'error',
          message: '请输入图形验证码！'
        })
        throw new Error("请输入图形验证码")
      }
    },
    //更新验证码
    captcheClick() {
      this.getImageCode()
    },
    changePhone() {
      if (this.passwordForm.phone && !isPhone(this.passwordForm.phone)) {
        this.dis = false
      }
    },
    // 发送验证码
    handlegetBtn() {
      if (this.passwordForm.phone && !isPhone(this.passwordForm.phone)) {
        let TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.isGrey = true
          this.show = false
          this.dis = true
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.dis = false
              this.isGrey = false
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)

          sendSMS({
            phone: this.passwordForm.phone,
            smsType: 3 //1 用户注册 2 更换手机号 3 忘记密码
          }).then((res) => {
            if (res.status === 200) {
              // console.log(res.data);
              this.$message({
                type: 'success',
                message: res.data.message
              })
            } else {
              this.$message.error(res.data.message)
            }
            // console.log(res);
          })
        }
      } else {
        this.$message.error('手机号码不能为空！')
      }
    },
    // 点击登录
    async handleSubmit() {
      try {
        await this.getCheck()
      } catch (error) {
        // handle error here, for example, show a message to user
        // this.$message({
        //   type: 'error',
        //   message: '验证码错误'
        // })
        return; // if getCheck() failed, stop the execution here
      }
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const { phone, captcha, password } = this.passwordForm
          const obj = {
            phone,
            password,
            smsCode: captcha, //必选	短信验证码
            captchaToken: this.captchaToken //验证码临时凭证
          }
          console.log(obj)
          passwordReset(obj).then((res) => {
            if (res.status === 200) {
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: '设置成功！',
                  onClose: () => {
                    this.$router.push({
                      path: '/'
                    })
                  }
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message,
                  onClose: () => {
                    this.loading = false
                  }
                })
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                onClose: () => {
                  this.loading = false
                }
              })
            }
          })
        }
      })
    },
    // 检查键盘是否打开了大写
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.find-password {
  height: 100%;
  background-color: #f5f5f5;
  .location {
    margin: 20px auto 0;
    // border:1px #f00 solid;
    width: 1170px;
    text-align: left;
  }
  .cont {
    margin: 16px auto 0;
    width: 1170px;
    .card {
      margin: 20px auto;
      .title {
        margin: 20px 0 0 20px;
        width: 70px;
        font-size: 16px;
        color: #000;
        font-weight: bold;
        border-bottom: 2px solid #0065b3;
        padding-bottom: 10px;
      }
      .form_width {
        width: 480px;
        margin: 80px auto;
        .item-wrap {
          display: flex;

          .captcha {
            width: 140px;
            margin-right: 12px;
          }
        }
        .phone,
        .password {
          float: left;
          width: 244px;
        }
        .captchaImage {
          float: left;
          width: 88px;
          height: 30px;
          border: 1px #eee solid;
        }
        .btn-wrap {
          text-align: center;
          float: left;
          margin: 0 0 0 180px;
          .submit-btn {
            width: 240px;
          }
        }
      }
    }
  }
}
</style>